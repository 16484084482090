import { Salon, Stylist } from 'src/app/models';

// import { Subject } from 'rxjs';
import { Intercom } from '@capacitor-community/intercom';
import { ENV_CONFIG } from 'src/bin/env.config';
import { ErrorHandlerService } from '../services/error-handler.service';
import { Auth } from '../services/login.service';
import { InAppPurchaseService } from '../services/inapp-purchase.service';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { OneSignal } from '@awesome-cordova-plugins/onesignal/ngx';
import { StylistService } from '../services/stylist.service';
import { Utilities } from '../utilities/utilities';
import { switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import * as Sentry from "@sentry/angular-ivy";

declare var intercom: any;
// declare var intercom: any;
declare var $crisp: any;
declare var window: any;
declare var posthog: any;

// export interface User {
//   user: any;
//   salon: any;
//   token: string;
// }

// declare global {
//     interface Window { $crisp: any; }
// }

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private loggedIn: boolean;

  private user: Stylist;

  private salon: Salon;

  private token: string;

  private logInMonitor = new BehaviorSubject<boolean>(false);

  private logInMonitor$ = this.logInMonitor.asObservable();

  private logOutMonitor = new BehaviorSubject<boolean>(false);

  private logOutMonitor$ = this.logOutMonitor.asObservable();

  public sessionMonitor = new BehaviorSubject<Auth>(undefined);

  private sessionMonitor$ = this.sessionMonitor.asObservable();

  private isSideBarShowing = true;

  private profileImageMonitor = new BehaviorSubject<any>(null);
  public profileImageMonitor$ = this.profileImageMonitor.asObservable();

  constructor(
    private platform: Platform,
    // private stylistService: StylistService,
    // private salonService: SalonService,
    private oneSignal: OneSignal,
    protected errorHandlerService: ErrorHandlerService,
    private inAppPurchaseService: InAppPurchaseService
  ) {
    this.platform.ready().then(() => {
      // window.$crisp=[];

      if (this.platform.is('capacitor')) {
        //   // START IN APP PURCHASE
        // this.inAppPurchaseService.init();

        //   // START One SIGNAL
        // this.oneSignal.startInit(
        //   ENV_CONFIG.oneSignalAppID,
        //   ENV_CONFIG.fireBaseSenderID
        // );

        // this.oneSignal.inFocusDisplaying(
        //   this.oneSignal.OSInFocusDisplayOption.Notification
        // );

        // this.oneSignal.handleNotificationReceived().subscribe(() => {
        //   // do something when notification is received
        // });

        // this.oneSignal.handleNotificationOpened().subscribe(() => {
        //   // do something when a notification is opened
        // });

        // this.oneSignal.endInit();
        const addNotificationReceivedListener = async () => {
          await FirebaseMessaging.addListener('notificationReceived', event => {
          });
        };

        const addNotificationActionPerformedListener = async () => {
          await FirebaseMessaging.addListener('notificationActionPerformed', event => {
          });
        };
      }
    });

    if (Utilities.isHTM5LocalStorageSupported()) {
      try {
        const auth: Auth = JSON.parse(localStorage.getItem('auth'));
        if (auth) {
          auth.user = JSON.parse(localStorage.getItem('user'));
          auth.salon = JSON.parse(localStorage.getItem('salon'));
          this.login(auth);
        } else {
          this.clearSession();
        }
      } catch (err) {
        this.clearSession();
      }
    } else {
      this.clearSession();
    }

    // refresh user's session immediately
    // this.refreshSession().subscribe();

    // refresh user and salon object every 15 mins
    // const fifteenMins = 1000 * 60 * 15;
    // setInterval(() => {
    //   this.refreshSession().subscribe();
    // }, fifteenMins);
  }

  // public refreshSession () : Observable<boolean> {

  //   return new Observable <boolean> (observer => {
  //     observer.next(true);
  //     observer.complete();
  //   });

  // return new Observable <boolean> (observer => {
  //   if (this.isLoggedIn()) {
  //     this.stylistService.loadStylistInfo(this.user.id)
  //       .pipe(
  //         switchMap((stylist: Stylist) => {
  //           this.setUser(stylist);
  //           return this.salonService.load();
  //         })
  //       )
  //       .subscribe((salon: Salon) => {
  //         this.setSalon(salon);

  //         this.sessionMonitor.next({
  //           user: this.user,
  //           salon: this.salon,
  //           token: undefined
  //         });

  //         observer.next(true);
  //         observer.complete();
  //       }, (err) => {
  //         observer.error(this.errorHandlerService.handleError(err));
  //         observer.complete();
  //       });
  //   } else {
  //     // observer.error(new Error('User is not logged in'));
  //     // observer.complete();
  //   }
  // });
  // }

  public login(data: Auth) {
    this.clear();
    const apiToken = data.token;
    const userData = data.user;
    const salonData = data.salon;
    const tokenObj = JSON.parse(apiToken);
    const userID = tokenObj.user_id;

    if (Utilities.isHTM5LocalStorageSupported()) {
      try {
        localStorage.setItem('auth', JSON.stringify(data));
        localStorage.setItem('token', apiToken);
        localStorage.setItem('userID', userID);
        localStorage.setItem('stylistID', userID);
        localStorage.setItem('salonID', tokenObj.salonID);
        localStorage.setItem('administrator', tokenObj.administrator);
      } catch (err) { }
    }
    const user: Stylist = Stylist.parseStylistData(userData);
    const salon: Salon = Salon.parse(salonData);
    this.setUser(user);
    this.setSalon(salon);
    this.token = apiToken;
    this.loggedIn = true;
    this.logInMonitor.next(true);

    const stylistFullName = user.getFirstName() + '' + user.getLastName();
    const stylistEmail = user.getEmail();
    const appVersion = salon.getAppVersion();
    const appBuildNum = ENV_CONFIG.appVersion;
    const salonName = salon.getName();
    const hasCalendar = (user.getCalendar() === 1) ? "Yes" : "No";
    const isAdmin = (user.isAdministrator()) ? "Yes" : "No";
    const canViewAllCalendars = (user.getViewAllCalendars() === 1) ? "Yes" : "No";
    const canViewAllClients = (user.getViewAllClients() === 1) ? "Yes" : "No";
    const hasSettingsControl = (user.getSettingsControl() === 1) ? "Yes" : "No";
    const requiresApptConfirmation = (user.getConfirmApptsManually() === 1) ? "Yes" : "No";
    const timeZone = salon.getTimeZone();
    const country = (this.salon.getCountry()) ? this.salon.getCountry().getCountryName() : '';
    const readOnly = (this.salon.isReadOnly()) ? "Yes" : "No";
    const salonWebsite = this.salon.getWebsite();
    const hasStripeAccount = (this.salon.hasStripeAccount()) ? "Yes" : "No";
    const stylistID = user.getID();


    // Link user to PostHog
    posthog.identify(this.user.getID() + '',
      {
        name: stylistFullName,
        email: stylistEmail,
        app_version: appVersion,
        app_build_num: appBuildNum,
        company_name: salonName,
        has_calendar: hasCalendar,
        hide_default_launcher: false,
        is_admin: isAdmin,
        can_view_all_calendars: canViewAllCalendars,
        can_view_all_clients: canViewAllClients,
        has_settings_control: hasSettingsControl,
        requires_appt_confirmation: requiresApptConfirmation,
        time_zone: timeZone,
        country: country,
        read_only: readOnly,
        salon_website: salonWebsite,
        has_stripe_account: hasStripeAccount,
      });


    Sentry.setUser({ id: stylistID, email: stylistEmail, username: stylistFullName });


    if (this.platform.is('capacitor')) {
      Intercom.registerIdentifiedUser({ userId: this.user.getID() + '', email: stylistEmail });
      Intercom.updateUser({
        name: stylistFullName,
        customAttributes: {
          has_calendar: hasCalendar,
          is_admin: isAdmin,
          can_view_all_calendars: canViewAllCalendars,
          can_view_all_clients: canViewAllClients,
          has_settings_control: hasSettingsControl,
          requires_appt_confirmation: requiresApptConfirmation,
          app_version: appVersion,
          app_build_num: appBuildNum,
          hide_default_launcher: !user.getIsChatBubbleEnabled(),
          company: {
            name: salonName,
            id: this.salon.getId(),
            time_zone: timeZone,
            country: country,
            read_only: readOnly,
            salon_website: salonWebsite,
            salon_email: this.salon.getEmail(),
            Has_stripe_account: hasStripeAccount
          }
        },

      })
      Intercom.setUserHash({ hmac: this.user.intercomUserHash });
      Intercom.displayLauncher();
    }

    if (window && window['Intercom']) {
      const intercom = window['Intercom'];

      const displayIntercomStr = localStorage.getItem('displayIntercom');
      let displayIntercom = displayIntercomStr !== null ? displayIntercomStr === 'true' : false;

      intercom('boot', {
        app_id: 'p3f80lct',
        user_id: this.user.getID() + '',
        email: stylistEmail,
        user_hash: this.user.intercomUserHash,
        name: stylistFullName,
        company: {
          name: salonName,
          id: this.salon.getId(),
          time_zone: timeZone,
          country: country,
          read_only: readOnly,
          salon_website: salonWebsite,
          salon_email: this.salon.getEmail(),
          Has_stripe_account: hasStripeAccount
        },
        // created_at: localStorage.getItem('creation_date'),
        has_calendar: hasCalendar,
        is_admin: isAdmin,
        can_view_all_calendars: canViewAllCalendars,
        can_view_all_clients: canViewAllClients,
        has_settings_control: hasSettingsControl,
        requires_appt_confirmation: requiresApptConfirmation,
        app_version: appVersion,
        app_build_num: appBuildNum,
        hide_default_launcher: displayIntercom
      });

    }
  }

  public isLoggedIn(): boolean {
    return this.loggedIn;
  }

  public getLogInMonitor(): Observable<boolean> {
    return this.logInMonitor$;
  }

  public getLogOutMonitor(): Observable<boolean> {
    return this.logOutMonitor$;
  }

  public getSessionMonitor(): Observable<Auth> {
    return this.sessionMonitor$;
  }

  public logout() {
    if (this.platform.is('capacitor') && this.isLoggedIn()) {
      this.oneSignal.deleteTag('stylistID');
    }

    this.clearSession();

    // clear posthog session
    posthog.reset();

    // clear intercom session
    if (window && window['Intercom']) {
      const intercom = window['Intercom'];
      intercom('shutdown');
    }

    if (this.platform.is("capacitor")) {
      Intercom.logout()
    }

    // broadcast log out status
    this.logOutMonitor.next(true);
  }

  public clearSession() {
    if (Utilities.isHTM5LocalStorageSupported()) {
      // lets grab the value of firstVisit before we clear() the whole localstorage
      const firstVisit = localStorage.getItem('firstVisit');
      localStorage.clear();
      localStorage.setItem('firstVisit', firstVisit);
    }
    this.clear();
  }

  public getUser(): Stylist {
    return this.user;
  }

  public setUser(user: Stylist) {
    this.user = user;

    if (Utilities.isHTM5LocalStorageSupported()) {
      localStorage.setItem('user', JSON.stringify(user.serialize()));
    }
  }

  public getSalon(): Salon {
    return this.salon;
  }

  public setSalon(salon: Salon) {
    this.salon = salon;
    if (Utilities.isHTM5LocalStorageSupported()) {
      localStorage.setItem('salon', JSON.stringify(this.salon.serialize()));
    }
  }

  public getToken(): string {
    return this.token;
  }

  private clear() {
    this.loggedIn = false;
    this.user = undefined;
    this.salon = undefined;
    this.token = undefined;
    // this.stylistService.setOutdated();

    if (window && window['Intercom']) {
      const intercom = window['Intercom'];
      intercom('shutdown');
    }

    if (this.platform.is("capacitor")) {
      Intercom.logout()
    }
  }

  public showIntercomChatBubble(): boolean {
    const hide = localStorage.getItem('hideHelpChatBubble');

    if (hide === 'true') {
      return false;
    } else {
      return true;
      // }
    }

    // return hidden;
  }

  public getSideBarVisibility(): boolean {
    return this.isSideBarShowing;
  }

  public setSideBarVisiblity(visible: boolean) {
    this.isSideBarShowing = visible;
  }

  public setHelpChatBubbleVisibility(show: boolean) {
    if (window && window['Intercom']) {
      const intercom = window['Intercom'];
      intercom('update', {
        hide_default_launcher: !show
      });
    }
    if (this.platform.is('capacitor')) {
      if (show) {
        Intercom.displayLauncher();
      } else {
        Intercom.hideLauncher();
      }
    }
    if (this.platform.is('capacitor')) {
      if (show) {
        Intercom.displayLauncher();
      } else {
        Intercom.hideLauncher();
      }
    }
  }


  updateProfileImg(newData: any) {
    this.profileImageMonitor.next(newData);
  }
}
