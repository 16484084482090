import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Output, EventEmitter } from '@angular/core';

import { Utilities } from '../../../utilities/utilities';
import { List, Record } from 'immutable';
import { CalendarEvent } from '../day-view-calendar/day-view-calendar.component';

const TimeSlotRecord = Record({
  hour: 0,  
  minutes: 0, 
  events: List([]),
  isAvailable: false,
  eventsStartIndex: 0,
  eventsOverlap: 0,
  hideLabels: false,
  showLeftMinuteLabels: false,
  muteEventTappability: false
});

export interface ITimeSlotOpts {
  hour: number;
  minutes: number;
  events: List<CalendarEvent>;
  isAvailable: boolean;
  eventsStartIndex: number,
  eventsOverlap: number,
  hideLabels: boolean,
  showLeftMinuteLabels: boolean,
  muteEventTappability?: boolean;
}

export class TimeSlot extends TimeSlotRecord { 
  public readonly hour: number;  
  public readonly minutes: number;
  public readonly events: List<CalendarEvent>;
  public readonly isAvailable: boolean;
  public readonly eventsStartIndex: number;
  public readonly eventsOverlap: number;
  public readonly hideLabels: boolean;
  public readonly showLeftMinuteLabels: boolean;
  public readonly muteEventTappability: boolean;

  constructor (params: ITimeSlotOpts) {
    super(params);
  }

  public setHour (hour: number): TimeSlot {
    return <TimeSlot> this.set('hour', hour);
  }

  public setMinutes (minutes: number): TimeSlot {
    return <TimeSlot> this.set('minutes', minutes);
  }

  public setEvents (events: List<CalendarEvent>): TimeSlot {
    return <TimeSlot> this.set('events', events);
  }

  public setAvailable (available: boolean): TimeSlot {
    return <TimeSlot> this.set('isAvailable', available);
  }

  public setEventsStartIndex (eventsStartIndex: number): TimeSlot {
    return <TimeSlot> this.set('eventsStartIndex', eventsStartIndex);
  }

  public setEventsOverlap (eventsOverlap: number): TimeSlot {
    return <TimeSlot> this.set('eventsOverlap', eventsOverlap);
  }  

  public setHideLabels (val: boolean): TimeSlot {
    return <TimeSlot> this.set('hideLabels', val);
  }

  public setShowLeftMinuteLabels (val: boolean): TimeSlot {
    return <TimeSlot> this.set('showLeftMinuteLabels', val);
  }

  public setMuteEventTappability(val: boolean): TimeSlot {
    return <TimeSlot> this.set('muteEventTappability', val);
  }
}
@Component({
  selector: '[smr-time-slot]',
  templateUrl: './time-slot.component.html',
  styleUrls: ['./time-slot.component.scss'],
})
export class TimeSlotComponent {

  @Input() tableRowIndex: number;

  @Input() timeslot: TimeSlot;

  @Output() timeSlotSelected = new EventEmitter<TimeSlot>();
  
  @Output() calendarEventSelected = new EventEmitter<CalendarEvent>();

  public isCellSelected: boolean;

  constructor (private changeDetectorRef: ChangeDetectorRef) {

  }

  public onTimeSlotSelected () {

    this.timeSlotSelected.emit(this.timeslot);
    this.isCellSelected = true;

    setTimeout(() => {
      this.isCellSelected = false;
      this.changeDetectorRef.markForCheck();
    }, 500);
  }

  public onEventSelected ($event, calendarEvent: CalendarEvent) {
    if (!this.timeslot.muteEventTappability) {
      this.calendarEventSelected.emit(calendarEvent);
      $event.stopPropagation();
    } else {
      return true;
    }
  }

  public eventDuration (event): number {
    return Utilities.timeDiffInMinutes(event.get('startTime'), event.get('endTime'));
  }

}
