import { UntypedFormControl } from '@angular/forms';

export interface ValidationResult {
  [key: string]: { valid: boolean };
}

export class CustomValidator {
  constructor() {}

  public static isEmailValid(email: string) {
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return EMAIL_REGEXP.test(email);
  }

  public static isNorthAmericanPhoneValid (phone: string) {
    const PHONE_REGEXP = /^\W?\d*?\W*?(?<area>\d{3})\W*?(?<group1>\d{3})\W*?(?<group2>\d{4})\W*?$/;
    return PHONE_REGEXP.test(phone);
  }
  public static isAustralianPhoneValid (phone: string) {
    const PHONE_REGEXP = /^(\+\d{2}[ \-]{0,1}){0,1}(((\({0,1}[ \-]{0,1})0{0,1}\){0,1}[2|3|7|8]{1}\){0,1}[ \-]*(\d{4}[ \-]{0,1}\d{4}))|(1[ \-]{0,1}(300|800|900|902)[ \-]{0,1}((\d{6})|(\d{3}[ \-]{0,1}\d{3})))|(13[ \-]{0,1}([\d \-]{5})|((\({0,1}[ \-]{0,1})0{0,1}\){0,1}4{1}[\d \-]{8,10})))$/;
    return PHONE_REGEXP.test(phone);
  }

  public static isNewZealandPhoneValid (phone: string) {
    const PHONE_REGEXP = /^(((\+?64\s*[-\.]?[3-9]|\(?0[3-9]\)?)\s*[-\.]?\d{3}\s*[-\.]?\d{4})|((\+?64\s*[-\.\(]?2\d{1}[-\.\)]?|\(?02\d{1}\)?)\s*[-\.]?\d{3}\s*[-\.]?\d{3,5})|((\+?64\s*[-\.]?[-\.\(]?800[-\.\)]?|[-\.\(]?0800[-\.\)]?)\s*[-\.]?\d{3}\s*[-\.]?(\d{2}|\d{5})))$/;
    return PHONE_REGEXP.test(phone);
  }

  public static isPhoneValid(phone: string) {
    // const PHONE_REGEXP = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    //const PHONE_REGEXP = /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d?)\)?)?[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?){0,})(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
    const PHONE_REGEXP = /\+?([\d|\(][\h|\(\d{3}\)|\.|\-|\d]{4,}\d)/;
    return PHONE_REGEXP.test(phone);
  }

  public static validateEmail(control: UntypedFormControl) {
    return CustomValidator.isEmailValid(control.value) ||
      control.value === '' ||
      !control.value
      ? null
      : {
          validateEmail: {
            valid: false
          }
        };
  }

  public static validatePhone(countryID: number = 0){
      return (control: UntypedFormControl) => {
        if(countryID === 124 || countryID === 581){
          return (CustomValidator.isNorthAmericanPhoneValid(control.value) || control.value === '' || !control.value) ? null : {
          validatePhone: { 
            valid: false
          }
        };
        } else if( countryID == 36 ) {
          return (CustomValidator.isAustralianPhoneValid(control.value) || control.value === '' || !control.value) ? null : {
          validatePhone: { 
            valid: false
          }
        };
        } else if( countryID == 554 ) {
        return (CustomValidator.isNewZealandPhoneValid(control.value) || control.value === '' || !control.value) ? null : {
          validatePhone: { 
            valid: false
          }
        };
      } else {
        return (CustomValidator.isPhoneValid(control.value) || control.value === '' || !control.value) ? null : {
          validatePhone: { 
            valid: false
          }
        };         
        }
    }
  }

  // public static clientEmailAvailabilityChecker (control: FormControl) : Observable <ValidationResult> {

  //   return new Observable <ValidationResult> ((observer) => {

  //     setTimeout(() => {
  //       observer.next({
  //         clientEmailAvailability: {
  //           valid: false
  //         }
  //       });

  //       observer.complete();
  //     }, 3000);

  //   });

  // }
}
