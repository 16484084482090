// -------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------
//
// CONFIG FILE FOR SALONMONSTER APP
//
// Sets all key constants to be used in the app.
//
// -------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------

export const ENV_CONFIG = {
  API_ROOT: 'https://api3.salonmonster.com/api',
  // API_ROOT: 'http://localhost:8000/api',
  WEB_ROOT: 'https://salonmonster.com',
  // WEB_ROOT: 'http://localhost:8100/',
  APP_ROOT: '8',
  appVersion: '8.2024071902',
  PHOTO_ROOT: 'https://res.cloudinary.com/salonmonster/image/upload/v1708470009/staff',
  STAFF_THUMBNAIL_PHOTO_ROOT:
    'https://res.cloudinary.com/salonmonster/image/upload/h_128,q_10,w_128/v1708470009/staff',
  BOOKING_PHOTO_FULL_RES_ROOT:
    'https://res.cloudinary.com/salonmonster/image/upload/h_1080,q_75,w_1080/v1531352315',
  BOOKING_PHOTO_ROOT:
    'https://res.cloudinary.com/salonmonster/image/upload/h_300,q_75,w_300,c_thumb,g_faces/v1531352315',
  THUMBNAIL_PHOTO_ROOT:
    'https://res.cloudinary.com/salonmonster/image/upload/e_blur:500,h_300,q_10,w_300,c_thumb,g_faces/v1531352315',
  facebookAppID: '426881094074823',
  facebookAPIVersion: 'v3.1',
  squareApplicationID: 'sq0idp-3C2yZ42JqAHeYne-yzK-rg',
  fireBaseSenderID: '65418111944',
  oneSignalAppID: 'e5b9afd0-96d2-4e86-8fb9-e18d8e286c0f',
  googleAnalyticsTrackingID: 'UA-117159482-1',
  SENTRY_DSN: 'https://557c0dedd42007783c5246e97158f5c5@o21453.ingest.sentry.io/4506596439425024',
  SENTRY_ENVIRONMENT: 'production',
  stripePublicKey: 'pk_live_X4A4x8QX3dPsmCMErLrOZ97g', //live
  STRIPE_CLIENT_ID: 'ca_HjED41B5whOg3RZG8j7UMCyWufp0cjFp' //live

  // stripePublicKey: 'pk_test_eP8cp9zagiYX0ZjpUU2aAoVA', //testing
  // STRIPE_CLIENT_ID: 'sk_test_cRezYzkdcWm70oQr6x68msyU' //testing

};

