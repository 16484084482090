import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SalonmonsterHttpClient } from './salonmonster-http-client';

import { ErrorHandlerService } from './error-handler.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { ENV_CONFIG } from '../../bin/env.config';
import { catchError, map } from 'rxjs/operators';
import { List } from 'immutable';
import { loadStripeTerminal } from '@stripe/terminal-js';
import { loadConnectAndInitialize } from '@stripe/connect-js';

export interface StripePayment {
  salonID: number;
  stripeUserID: string;
  accessToken: string;
  refreshToken: string;
  stripePublishableKey: string;
  scope: string;
  liveMode: boolean;
}

export interface RegisterTerminalData {
  registration_code: string;
}

interface StripeLocation {
  stripeLocation: string;
}

@Injectable()
export class StripeService extends SalonmonsterHttpClient {
  public terminal;
  public connectedTerminals = [];
  constructor(
    http: HttpClient,
    userService: UserService,
    protected errorHandlerService: ErrorHandlerService
  ) {
    super(http, userService, errorHandlerService);
  }

  public loadStripeAccountInfo(): Observable<{
    stripeAccountInfo: List<StripePayment>;
  }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/me`;
    return new Observable<{ stripeAccountInfo: List<StripePayment> }>(
      (observer) => {
        this.get(url)
          .pipe(
            map((res) => {
              const stripeInfoArray = res;
              let stripeAccountInfo: List<StripePayment> = List([]);

              for (let row of stripeInfoArray) {
                stripeAccountInfo = stripeAccountInfo.push(row);
              }
              return { stripeAccountInfo };
            })
          )

          .subscribe({
            next: (stripeAccountInfo) => {
              observer.next(stripeAccountInfo);
              observer.complete();
            },
            error: (err) => {
              observer.error(this.errorHandlerService.handleError(err));
              observer.complete();
            },
          });
      }
    );
  }

  public insertStripeAccountInfo(
    params
  ): Observable<{ stripeAccountInfo: StripePayment }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/oauth/callback?code=${params['code']}&state=${params['state']}`;
    return new Observable<{ stripeAccountInfo: StripePayment }>((observer) => {
      this.get(url).subscribe({
        next: (stripeAccountInfo) => {
          observer.next(stripeAccountInfo);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public registerNewTerminal(
    registration_code,
    hasAddress,
    address,
    locationID
  ): Observable<{ registerData: RegisterTerminalData }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/register`;
    const salonID = localStorage.getItem('salonID');

    return new Observable<{ registerData: RegisterTerminalData }>(
      (observer) => {
        this.post(url, {
          registration_code,
          hasAddress,
          address,
          locationID,
          salonID,
        }).subscribe({
          next: (res) => {
            const newTerminal = res;
            observer.next(newTerminal);
            observer.complete();
          },
          error: (err) => {
            observer.error(this.errorHandlerService.handleError(err));
            observer.complete();
          },
        });
      }
    );
  }

  public getStripeLocation(): Observable<{ locationID: StripeLocation }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/location_id`;
    // const salonID = localStorage.getItem("salonID");
    return new Observable<{ locationID: StripeLocation }>((observer) => {
      this.get(url).subscribe({
        next: (res) => {
          const locationID = res;
          observer.next(locationID.stripeLocation);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  private async fetchConnectionToken() {
    // Your backend should call /v1/terminal/connection_tokens and return the JSON response from Stripe
    const url = `${ENV_CONFIG.API_ROOT}/stripe/connection_token`;
    const response = await fetch(url, {
      method: 'POST',
      headers: new Headers({ authorization: localStorage.getItem('token') }),
    });
    const res = await response.json();
    return res.data.secret;
  }

  public async unexpectedDisconnect() { }

  public async setTerminal() {
    const StripeTerminal = await loadStripeTerminal();
    this.terminal = StripeTerminal.create({
      onFetchConnectionToken: this.fetchConnectionToken,
      onUnexpectedReaderDisconnect: this.unexpectedDisconnect,
    });
  }

  public getTerminal() {
    return this.terminal;
  }

  public async connectReader(reader) {
    await this.setTerminal();
    const terminal = this.getTerminal();
    if (
      this.connectedTerminals.length === 0 ||
      this.searchInConnectedTerminals(reader, this.connectedTerminals) === -1
    ) {
      this.connectedTerminals.push({ reader, terminal });
    } else {
      this.connectedTerminals.splice(
        this.searchInConnectedTerminals(reader, this.connectedTerminals),
        1
      );
      this.connectedTerminals.push({ reader, terminal });
    }
    const connectResponse = await terminal.connectReader(reader);
    if (!connectResponse['error']) {
      localStorage.setItem('cardReader', JSON.stringify(reader));
    }
    return connectResponse;
  }

  public async getStatus(reader) {
    const readerIndex = this.searchInConnectedTerminals(
      reader,
      this.connectedTerminals
    );
    if (this.connectedTerminals.length === 0 || readerIndex === -1) {
      return 'No reader connected';
    }
    const { terminal } = this.connectedTerminals[readerIndex];
    return terminal.getConnectionStatus();
  }

  public async disconnectReader(reader) {
    if (this.connectedTerminals.length === 0) {
      return 'No reader connected';
    }
    const { terminal } =
      this.connectedTerminals[
      this.searchInConnectedTerminals(reader, this.connectedTerminals)
      ];
    return terminal.disconnectReader();
  }

  public async discoverReaders() {
    const locationID = await new Promise((resolve) =>
      this.getStripeLocation().subscribe((res) => {
        resolve(res);
      })
    );
    let config;
    if (locationID === null) {
      //todo: add logical error handling if no location found
      return { message: 'No available readers.', code: 'reader_not_found' };
    } else {
      config = { simulated: false, location: locationID };
    }

    await this.setTerminal();
    let terminal = this.getTerminal();
    const discoverResult = await terminal.discoverReaders(config);
    if (discoverResult['error']) {
      return discoverResult['error'];
    } else if (discoverResult['discoveredReaders'].length === 0) {
      return { message: 'No available readers.', code: 'reader_not_found' };
    } else {
      // You should show the list of discoveredReaders to the
      // cashier here and let them select which to connect to (see below).
      return discoverResult;
    }
  }

  public createPaymentIntentWithReader(params): Observable<any> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/payment_intent_reader`;
    const salonID = localStorage.getItem('salonID');
    let { amount, currency } = params;
    // remove decimal from amount - it needs to be sent to stripe in cents with no decimal
    amount = Math.round(amount);
    return new Observable<any>((observer) => {
      this.post(url, { amount, currency, salonID }).subscribe({
        next: (res) => {
          const clientSecret = res;
          observer.next(clientSecret);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }
  public createPaymentIntentWithCard(params): Observable<any> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/payment_intent_card`;
    const salonID = localStorage.getItem('salonID');
    const { rawAmount, currency, saveClient, clientID, paymentType } = params;
    const amount = Math.round(rawAmount);
    return new Observable<any>((observer) => {
      this.post(url, {
        amount,
        currency,
        saveClient,
        clientID,
        salonID,
        paymentType,
      }).subscribe({
        next: (res) => {
          const response = res;
          observer.next(response);
          observer.complete();
        },
        error: (err) => {
          // failing silently here!
          const errorJson = JSON.parse(err._body);
          this.errorHandlerService.handleError(err);
          observer.error(new Error(errorJson.raw.message));
          observer.complete();
        },
      });
    });
  }

  public chargeSavedCard(params): Observable<any> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/charge_saved_card`;
    const { amount, currency, clientID, salonID } = params;
    return new Observable<any>((observer) => {
      this.post(url, { amount, currency, clientID, salonID }).subscribe({
        next: (res) => {
          const response = res;
          observer.next(response);
          observer.complete();
        },
        error: (err: HttpErrorResponse) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public getCustomerCardInfo(params): Observable<any> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/customer_card`;
    const { clientID } = params;
    return new Observable<any>((observer) => {
      this.post(url, { clientID }).subscribe({
        next: (res) => {
          const response = res;
          observer.next(response);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public getClientStripeID(params): Observable<any> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/client_stripeID`;
    const { clientID } = params;
    return new Observable<any>((observer) => {
      this.post(url, { clientID }).subscribe({
        next: (res) => {
          const response = res;
          observer.next(response);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }
  public async collectPaymentMethod(reader, clientSecret) {
    const { terminal } =
      this.connectedTerminals[
      this.searchInConnectedTerminals(reader, this.connectedTerminals)
      ];
    const result = await terminal.collectPaymentMethod(clientSecret);
    if (result.error) {
      // Placeholder for handling result.error
      return result;
    } else {
      // Placeholder for processing result.paymentIntent
      return result.paymentIntent;
    }
  }

  public async processPayment(paymentIntent, reader) {
    const { terminal } =
      this.connectedTerminals[
      this.searchInConnectedTerminals(reader, this.connectedTerminals)
      ];
    const result = await terminal.processPayment(paymentIntent);
    if (result.error) {
      // Placeholder for handling result.error
      return result;
    } else if (result.paymentIntent) {
      // Placeholder for notifying your backend to capture result.paymentIntent.id

      // update paymentIntent with applicationFee


      // capture the payment

      // check if it is card_present or interac_present
      if (
        'card_present' in
        result.paymentIntent.charges.data[0].payment_method_details
      ) {
        const { brand, funding } =
          result.paymentIntent.charges.data[0].payment_method_details
            .card_present;
        const paymentType = this.stripePaymentType(brand, funding);
        const amount = result.paymentIntent.amount;
        const amountDetails = result.paymentIntent.amount_details;
        const paymentMethod = result.paymentIntent.charges.data[0].payment_method_details;
        const paymentChargeID = result.paymentIntent.charges.data[0].id;
        const applicationFee = result.paymentIntent.charges.data[0].application_fee_amount;
        return { paymentIntentID: result.paymentIntent.id, paymentMethod, paymentType, amount, amountDetails, isInterac: false, paymentChargeID, applicationFee };
      } else if (
        'interac_present' in
        result.paymentIntent.charges.data[0].payment_method_details
      ) {
        const { brand, funding } =
          result.paymentIntent.charges.data[0].payment_method_details
            .interac_present;
        const paymentType = this.stripePaymentType(brand, funding);
        const amount = result.paymentIntent.amount;
        const amountDetails = result.paymentIntent.amount_details;
        const paymentMethod = result.paymentIntent.charges.data[0].payment_method_details;
        const paymentChargeID = result.paymentIntent.charges.data[0].id;
        const applicationFee = result.paymentIntent.charges.data[0].application_fee_amount;
        return { paymentIntentID: result.paymentIntent.id, paymentMethod, paymentType, amount, amountDetails, isInterac: true, paymentChargeID, applicationFee };
      } else {
        // Placeholder for handling result.error
        return result;
      }
    }
  }

  public capturePayment(paymentIntentID): Observable<any> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/capture_payment_intent`;
    return new Observable<any>((observer) => {
      this.post(url, { paymentIntentID }).subscribe({
        next: (res: any) => {
          const response = res;
          observer.next(response);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public clientIntentSecret(params): Observable<any> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/intent_secret`;
    const { clientID, newClient, clientName } = params;
    return new Observable<any>((observer) => {
      this.post(url, { clientID, newClient, clientName }).subscribe({
        next: (res: any) => {
          const response = res;
          observer.next(response.clientSecret);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  // unused can probably delete
  public sendPaymentIntentID(paymentIntentID): Observable<{ res: object }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/capture_payment_intent`;
    return new Observable<{ res: object }>((observer) => {
      this.post(url, { paymentIntentID }).subscribe({
        next: (res) => {
          const data = res;
          observer.next(data);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public detachPaymentMethod(params): Observable<{ res: object }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/detach_payment_method`;
    const { paymentMethodID } = params;
    return new Observable<{ res: object }>((observer) => {
      this.post(url, { paymentMethodID }).subscribe({
        next: (res) => {
          const data = res;
          observer.next(data);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public getPaymentMethod(params): Observable<{ res: object }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/payment_method`;
    const { paymentMethodID } = params;
    return new Observable<{ res: object }>((observer) => {
      this.post(url, { paymentMethodID }).subscribe({
        next: (res) => {
          const data = res;
          observer.next(data);
          observer.complete();
        },
        error: (err) => {
          console.error('error getting payment method', err);
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public getDeclinedInformation(decline_code: string) {
    let stripe_decline_codes = {
      authentication_required: {
        description:
          'The card was declined as the transaction requires authentication.',
        next_steps:
          'The customer should try again and authenticate their card when prompted during the transaction.If the card issuer returns this decline code on an authenticated transaction, the customer needs to contact their card issuer for more information.',
      },
      approve_with_id: {
        description: "The payment can't be authorized.",
        next_steps:
          "Attempt the payment again. If you still can't process it, the customer needs to contact their card issuer.",
      },
      call_issuer: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      card_not_supported: {
        description: 'The card does not support this type of purchase.',
        next_steps:
          'The customer needs to contact their card issuer to make sure their card can be used to make this type of purchase.',
      },
      card_velocity_exceeded: {
        description:
          'The customer has exceeded the balance or credit limit available on their card.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      currency_not_supported: {
        description: 'The card does not support the specified currency.',
        next_steps:
          'The customer needs to check with the issuer whether the card can be used for the type of currency specified.',
      },
      do_not_honor: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      do_not_try_again: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      duplicate_transaction: {
        description:
          'A transaction with identical amount and credit card information was submitted very recently.',
        next_steps: 'Check to see if a recent payment already exists.',
      },
      expired_card: {
        description: 'The card has expired.',
        next_steps: 'The customer needs to use another card.',
      },
      fraudulent: {
        description:
          "The payment was declined because Stripe suspects that it's fraudulent.",
        next_steps:
          "Don't report more detailed information to your customer. Instead, present as you would the generic_decline described below.",
      },
      generic_decline: {
        description:
          'The card was declined for an unknown reason or possibly triggered by a blocked payment rule.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      incorrect_number: {
        description: 'The card number is incorrect.',
        next_steps:
          'The customer needs to try again using the correct card number.',
      },
      incorrect_cvc: {
        description: 'The CVC number is incorrect.',
        next_steps: 'The customer needs to try again using the correct CVC.',
      },
      incorrect_pin: {
        description:
          'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
        next_steps: 'The customer needs to try again using the correct PIN.',
      },
      incorrect_zip: {
        description: 'The postal code is incorrect.',
        next_steps:
          'The customer needs to try again using the correct billing postal code.',
      },
      insufficient_funds: {
        description:
          'The card has insufficient funds to complete the purchase.',
        next_steps: 'The customer needs to use an alternative payment method.',
      },
      invalid_account: {
        description:
          'The card, or account the card is connected to, is invalid.',
        next_steps:
          'The customer needs to contact their card issuer to check that the card is working correctly.',
      },
      invalid_amount: {
        description:
          "The payment amount is invalid, or exceeds the amount that's allowed.",
        next_steps:
          'If the amount appears to be correct, the customer needs to check with their card issuer that they can make purchases of that amount.',
      },
      invalid_cvc: {
        description: 'The CVC number is incorrect.',
        next_steps: 'The customer needs to try again using the correct CVC.',
      },
      invalid_expiry_month: {
        description: 'The expiration month is invalid.',
        next_steps:
          'The customer needs to try again using the correct expiration date.',
      },
      invalid_expiry_year: {
        description: 'The expiration year is invalid.',
        next_steps:
          'The customer needs try again using the correct expiration date.',
      },
      invalid_number: {
        description: 'The card number is incorrect.',
        next_steps:
          'The customer needs try again using the correct card number.',
      },
      invalid_pin: {
        description:
          'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
        next_steps: 'The customer needs to try again using the correct PIN.',
      },
      issuer_not_available: {
        description:
          "The card issuer couldn't be reached, so the payment couldn't be authorized.",
        next_steps:
          "Attempt the payment again. If you still can't process it, the customer needs to contact their card issuer.",
      },
      lost_card: {
        description:
          'The payment was declined because the card is reported lost.',
        next_steps:
          "The specific reason for the decline shouldn't be reported to the customer. Instead, it needs to be presented as a generic decline.",
      },
      merchant_blacklist: {
        description:
          "The payment was declined because it matches a value on the Stripe user's block list.",
        next_steps:
          "Don't report more detailed information to your customer. Instead, present as you would the generic_decline described above.",
      },
      new_account_information_available: {
        description:
          'The card, or account the card is connected to, is invalid.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      no_action_taken: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      not_permitted: {
        description: "The payment isn't permitted.",
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      offline_pin_required: {
        description: 'The card was declined because it requires a PIN.',
        next_steps:
          'The customer needs to try again by inserting their card and entering a PIN.',
      },
      online_or_offline_pin_required: {
        description: 'The card was declined as it requires a PIN.',
        next_steps:
          "If the card reader supports Online PIN, prompt the customer for a PIN without creating a new transaction. If the card reader doesn't support Online PIN, the customer needs to try again by inserting their card and entering a PIN.",
      },
      pickup_card: {
        description:
          "The customer can't use this card to make this payment (it's possible it was reported lost or stolen).",
        next_steps:
          'They need to contact their card issuer for more information.',
      },
      pin_try_exceeded: {
        description: 'The allowable number of PIN tries was exceeded.',
        next_steps: 'The customer must use another card or method of payment.',
      },
      processing_error: {
        description: 'An error occurred while processing the card.',
        next_steps:
          "The payment needs to be attempted again. If it still can't be processed, try again later.",
      },
      reenter_transaction: {
        description:
          "The payment couldn't be processed by the issuer for an unknown reason.",
        next_steps:
          "The payment needs to be attempted again. If it still can't be processed, the customer needs to contact their card issuer.",
      },
      restricted_card: {
        description:
          "The customer can't use this card to make this payment (it's possible it was reported lost or stolen).",
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      revocation_of_all_authorizations: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      revocation_of_authorization: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      security_violation: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      service_not_allowed: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      stolen_card: {
        description:
          'The payment was declined because the card is reported stolen.',
        next_steps:
          "The specific reason for the decline shouldn't be reported to the customer. Instead, it needs to be presented as a generic decline.",
      },
      stop_payment_order: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      testmode_decline: {
        description: 'A Stripe test card number was used.',
        next_steps: 'A genuine card must be used to make a payment.',
      },
      transaction_not_allowed: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'The customer needs to contact their card issuer for more information.',
      },
      try_again_later: {
        description: 'The card was declined for an unknown reason.',
        next_steps:
          'Ask the customer to attempt the payment again. If subsequent payments are declined, the customer needs to contact their card issuer for more information.',
      },
      withdrawal_count_limit_exceeded: {
        description:
          'The customer has exceeded the balance or credit limit available on their card.',
        next_steps: 'The customer needs to use an alternative payment method.',
      },
    };

    let description = stripe_decline_codes[decline_code].description;
    let next_steps = stripe_decline_codes[decline_code].next_steps;
    return {
      description: description,
      next_steps: next_steps,
    };
  }

  public getViewAccountSettingsLink(params): Observable<{ res: object }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/dashboard/settings`;
    const { stripeUserId } = params;
    return new Observable<{ res: object }>((observer) => {
      this.post(url, { stripeUserId }).subscribe({
        next: (res) => {
          const data = res;
          observer.next(data);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

  public searchInConnectedTerminals(value, array: Array<object>) {
    for (let i = 0; i < array.length; i++) {
      if (array[i]['reader']['id'] === value['id']) {
        return i;
      }
    }
    return -1;
  }

  public stripePaymentType(brand, funding) {
    let payment: { type: number; name: string; amount: number };
    if (funding === 'debit') {
      payment = { type: 20, name: 'StripeDebit', amount: 0 };
    } else if (funding === 'credit') {
      switch (brand) {
        case 'visa':
          payment = { type: 21, name: 'StripeVisa', amount: 0 };
          break;
        case 'mastercard':
          payment = { type: 22, name: 'StripeMastercard', amount: 0 };
          break;
        case 'amex':
          payment = { type: 23, name: 'StripeAmex', amount: 0 };
          break;
        case 'diners':
          payment = { type: 24, name: 'StripeDiners', amount: 0 };
          break;
        case 'discover':
          payment = { type: 25, name: 'StripeDiscover', amount: 0 };
          break;
        case 'jcb':
          payment = { type: 26, name: 'StripeJcb', amount: 0 };
          break;
        case 'unionpay':
          payment = { type: 27, name: 'StripeUnionpay', amount: 0 };
          break;
        default:
          payment = { type: 28, name: 'StripeUnknown', amount: 0 };
      }
    } else if (funding === 'prepaid') {
      payment = { type: 29, name: 'StripePrepaid', amount: 0 };
    } else {
      payment = { type: 28, name: 'StripeUnknown', amount: 0 };
    }
    return payment;
  }

  async cancelReaderAction(reader) {
    const { terminal } =
      this.connectedTerminals[
      this.searchInConnectedTerminals(reader, this.connectedTerminals)
      ];
    const result = await terminal.cancelCollectPaymentMethod();
    return result;
    // const url = `${ENV_CONFIG.API_ROOT}/stripe/terminal/cancel-reader-action`;
    // return new Observable<any>((observer) => {
    //   this.post(url, { id }).subscribe({
    //     next: (res) => {
    //       const response = res;
    //       observer.next(response);
    //       observer.complete();
    //     },
    //     error: (err) => {
    //       observer.error(this.errorHandlerService.handleError(err));
    //       observer.complete();
    //     },
    //   });
    // });
  }

  public refund(params): Observable<{ res: object }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/refund`;
    const { paymentIntentID, amount } = params;
    return new Observable<{ res: object }>((observer) => {
      this.post(url, { paymentIntentID, amount }).subscribe({
        next: (res) => {
          const data = res;
          observer.next(data);
          observer.complete();
        },
        error: (err) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        }
      });
    });
  }

  public async refundDebit(reader, params) {
    const { terminal } =
      this.connectedTerminals[
      this.searchInConnectedTerminals(reader, this.connectedTerminals)
      ];

    const { paymentChargeID, amount, currency } = params;
    const result = await terminal.collectRefundPaymentMethod(
      paymentChargeID,
      amount,
      currency
    );

    if (result.error) {
      return result
      // Placeholder for handling result.error
    } else {
      const refund = await terminal.processRefund();
      if (refund.error) {
        return refund
        // Placeholder for handling refund.error
      } else {
        return refund;
      }
    }
  }

  public fetchClientSecret(params): Observable<{ res: object }> {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/account_session`;
    const { accountID } = params;
    return this.post(url, { accountID }).pipe(
      map((data) => {
        return data;
      }),
      catchError(err => this.errorHandlerService.handleError(err))
    );
  }

  public createStripeConnectInstance(clientSecret) {
    const stripeConnectInstance = loadConnectAndInitialize({
      // This is your test publishable API key.
      publishableKey: ENV_CONFIG.stripePublicKey,
      fetchClientSecret: () => clientSecret,

    });
    return stripeConnectInstance
  }

  public fetchAllCharges() {
    const url = `${ENV_CONFIG.API_ROOT}/stripe/charges`;
    return new Observable<any>((observer) => {
      this.get(url).subscribe({
        next: (res) => {
          const response = res;
          observer.next(response);
          observer.complete();
        },
        error: (err: HttpErrorResponse) => {
          observer.error(this.errorHandlerService.handleError(err));
          observer.complete();
        },
      });
    });
  }

}
